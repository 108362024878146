<template>
    <div class="mycard">
        <v-card-text class="card-text">
            Регистрация
            <v-form @submit.prevent="validate" novalidate>
                <v-select
                    class="mt-5"
                    :items="gradesToShow"
                    v-model="grade"
                    item-text="fullName"
                    label="Выберите класс обучения"
                    :error-messages="gradeErrors"
                    required
                    @input="$v.grade.$touch()"
                    @blur="$v.grade.$touch()"
                    prepend-icon="mdi-view-sequential"
                >
                </v-select>

                <v-select
                    v-if="showSubprofiles"
                    class="mt-1"
                    :items="subprofiles"
                    v-model="subprofile"
                    :error-messages="subprofileErrors"
                    required
                    @input="$v.subprofile.$touch()"
                    @blur="$v.subprofile.$touch()"
                    item-text="Предмет"
                    label="Выберите предмет"
                    prepend-icon="mdi-view-sequential"
                    multiple
                >
                </v-select>
                <v-row>
                    <v-col cols="12" md="6" class="nopadding"
                        ><v-text-field
                            v-model.trim="surname"
                            label="Фамилия участника"
                            :error-messages="surnameErrors"
                            required
                            @input="$v.surname.$touch()"
                            @blur="$v.surname.$touch()"
                            prepend-icon="mdi-account"
                        ></v-text-field>
                        <v-text-field
                            v-model.trim="name"
                            label="Имя участника"
                            :error-messages="nameErrors"
                            required
                            @input="$v.name.$touch()"
                            @blur="$v.name.$touch()"
                            prepend-icon="mdi-account"
                        ></v-text-field>
                        <v-text-field
                            v-model.trim="patronymic"
                            label="Отчество участника"
                            :error-messages="patronymicErrors"
                            required
                            @input="$v.patronymic.$touch()"
                            @blur="$v.patronymic.$touch()"
                            prepend-icon="mdi-account"
                        ></v-text-field>
                        <v-text-field
                            v-mask="'##.##.####'"
                            v-model="birth"
                            label="Дата рождения участника"
                            :error-messages="birthErrors"
                            required
                            @input="$v.birth.$touch()"
                            @blur="$v.birth.$touch()"
                            prepend-icon="mdi-calendar-range"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12" md="6" class="nopadding">
                        <v-text-field
                            v-mask="'###-###-### ##'"
                            v-model="snils"
                            label="СНИЛС участника"
                            :error-messages="snilsErrors"
                            required
                            @input="$v.snils.$touch()"
                            @blur="$v.snils.$touch()"
                            prepend-icon="mdi-badge-account-horizontal"
                        ></v-text-field>
                        <v-text-field
                            v-mask="'#-###-###-##-##'"
                            v-model="phone"
                            label="Контактный телефон родителя"
                            :error-messages="phoneErrors"
                            required
                            @input="$v.phone.$touch()"
                            @blur="$v.phone.$touch()"
                            prepend-icon="mdi-phone"
                        ></v-text-field>
                        <v-text-field
                            type="email"
                            v-model="email"
                            label="Адрес эл. почты (1 регистрация = 1 почта). Укажите личную, а не рабочую почту."
                            :error-messages="emailErrors"
                            required
                            @input="$v.email.$touch()"
                            @blur="$v.email.$touch()"
                            prepend-icon="mdi-mail"
                        ></v-text-field>
                        <v-text-field
                            v-model="currentSchool"
                            label="Номер школы обучения сейчас"
                            :error-messages="currentSchoolErrors"
                            required
                            @input="$v.currentSchool.$touch()"
                            @blur="$v.currentSchool.$touch()"
                            prepend-icon="mdi-school"
                        ></v-text-field
                    ></v-col>
                </v-row>
                <v-select
                    :items="confirmGrades"
                    v-model="confirmGrade"
                    item-text="fullName"
                    label="Класс обучение участника сейчас"
                    :error-messages="confirmGradeErrors"
                    required
                    @input="$v.confirmGrade.$touch()"
                    @blur="$v.confirmGrade.$touch()"
                    prepend-icon="mdi-view-sequential"
                >
                </v-select>
                <v-checkbox
                    id="terms1"
                    @change="$v.terms1.$touch()"
                    v-model="terms1"
                    @blur="$v.terms.$touch()"
                >
                    <template v-slot:label>
                        Я даю свое согласие на обработку своих персональных
                        данных и данных ребенка, родителем (законным
                        представителем) которого я являюсь.
                    </template>
                </v-checkbox>

                <v-checkbox
                    id="terms2"
                    @change="$v.terms2.$touch()"
                    v-model="terms2"
                >
                    <template v-slot:label>
                        <div>
                            Я ознакомлен с
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a
                                        target="_blank"
                                        href="https://lyc1535.mskobr.ru/uchashimsya/olimpiady-konkursy-konferencii/olimpiada-orbita-1535"
                                        @click.stop
                                        v-on="on"
                                    >
                                        Положением об Олимпиаде "Орбита 1535" и
                                        приложениями к нему
                                    </a>
                                </template>
                                Откроется в новом окне
                            </v-tooltip>
                        </div>
                    </template>
                </v-checkbox>
                <v-checkbox
                    id="terms3"
                    @change="$v.terms3.$touch()"
                    v-model="terms3"
                >
                    <template v-slot:label>
                        <div>
                            Я ознакомлен с
                            <v-tooltip bottom>
                                <template v-slot:activator="{ on }">
                                    <a
                                        target="_blank"
                                        href="https://drive.google.com/file/d/13r9Asud2S9OdzMRuRT_v4l7lsjWkQ7Vo/view"
                                        @click.stop
                                        v-on="on"
                                    >
                                        регламентом проведения Олимпиады в
                                        дистанционном формате.
                                    </a>
                                </template>
                                Откроется в новом окне
                            </v-tooltip>
                        </div>
                    </template>
                </v-checkbox>
            </v-form>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <vue-recaptcha
                ref="recaptcha"
                size="invisible"
                :sitekey="sitekey"
                @verify="submitEnrollee"
                @expired="onCaptchaExpired"
            />

            <v-btn
                type="submit"
                color="primary"
                @click="validate"
                :disabled="$v.$invalid"
                style="width: 120px"
                >Отправить</v-btn
            >
        </v-card-actions>
    </div>
</template>
<script>
import VueRecaptcha from 'vue-recaptcha'
import { required, minLength, sameAs, email } from 'vuelidate/lib/validators'
import { bus } from '../main'
export default {
    data() {
        return {
            sitekey: '6Le-imQaAAAAAC_Bgq1s2haE9A1IGTEL6YgLo2N2',
            grade: null,
            surname: '',
            name: '',
            patronymic: '',
            birth: '',
            snils: '',
            phone: '',
            email: '',
            currentSchool: '',
            terms1: false,
            terms2: false,
            terms3: false,
            grades: [],
            showSubprofiles: false,
            subprofiles: [],
            subprofile: [],
            confirmGrades: [6, 7, 9],
            confirmGrade: '',
        }
    },
    components: { VueRecaptcha },
    name: 'Login',
    props: {
        source: String,
    },

    methods: {
        capitalize(string) {
            return (
                string.charAt(0).toUpperCase() + string.slice(1).toLowerCase()
            )
        },
        submitEnrollee(recaptchaToken) {
            const enrollee = {
                profile: this.grade,
                subprofile: this.subprofile,
                surname: this.capitalize(this.surname),
                name: this.capitalize(this.name),
                patronymic: this.capitalize(this.patronymic),
                birth: this.birth,
                snils: this.snils,
                phone: this.phone,
                email: this.email.toLowerCase(),
                currentSchool: this.currentSchool,
                recaptchaToken: recaptchaToken,
                confirmGrade: this.confirmGrade,
            }
            this.$axios({
                url: '/api/submit-enrollee/',
                data: enrollee,
                method: 'POST',
            }).then((data) => {
                if (data.data.answer == 'fail') {
                    bus.$emit('snackbar', data.data.text)
                    this.onCaptchaExpired()
                } else {
                    bus.$emit('snackbar', data.data.text)
                    this.$emit('formSubmitted')
                    this.clearForm()
                }
            })
        },
        validate(event) {
            event.preventDefault()
            const selectedGrade = this.grade.split(' - ')[0]
            if (+this.confirmGrade !== +selectedGrade) {
                bus.$emit(
                    'snackbar',
                    'Не совпадает указанный класс обучения и класс для прохождения олимпиады (должны быть одинаковые). Обращаем ваше внимание, что принять участие можно только за тот класс, в котором участник олимпиады учится сейчас.'
                )
            } else {
                this.$refs.recaptcha.execute()
            }
        },
        onCaptchaExpired() {
            this.$refs.recaptcha.reset()
        },
        clearForm() {
            ;(this.grade = null),
                (this.surname = ''),
                (this.name = ''),
                (this.patronymic = ''),
                (this.birth = ''),
                (this.snils = ''),
                (this.phone = ''),
                (this.email = ''),
                (this.currentSchool = '')
            this.confirmGrade = null
        },
    },
    created() {
        this.$axios.get('/api/profiles').then((a) => {
            this.grades = a.data.profileList
        })
        const $script = document.createElement('script')
        $script.async = true
        $script.src =
            'https://www.google.com/recaptcha/api.js?onload=vueRecaptchaApiLoaded&render=explicit'
        document.head.appendChild($script)
    },
    watch: {
        grade: function () {
            if (this.grade) {
                this.subprofile = null
                this.grades.forEach((profile) => {
                    if (profile.fullName == this.grade) {
                        if (profile.subprofilesEnabled) {
                            this.showSubprofiles = true
                            this.subprofiles = profile.subprofilesEnabled
                        } else {
                            this.subprofile = []
                            this.showSubprofiles = false
                        }
                    }
                })
            }
        },
    },
    computed: {
        gradesToShow() {
            return this.grades.filter(function (grade) {
                return !grade.disabled
            })
        },
        emailErrors() {
            const errors = []
            if (!this.$v.email.$dirty) return errors
            !this.$v.email.email && errors.push('Неверный формат почты')
            !this.$v.email.required && errors.push('Обязательное поле')
            return errors
        },
        subprofileErrors() {
            const errors = []
            if (!this.$v.subprofile.$dirty) return errors
            !this.$v.subprofile.required && errors.push('Обязательное поле')
            return errors
        },
        surnameErrors() {
            const errors = []
            if (!this.$v.surname.$dirty) return errors
            !this.$v.surname.minLength && errors.push('Минимум 2 символа')
            !this.$v.surname.required && errors.push('Обязательное поле')
            return errors
        },
        nameErrors() {
            const errors = []
            if (!this.$v.name.$dirty) return errors
            !this.$v.name.minLength && errors.push('Минимум 2 символа')
            !this.$v.name.required && errors.push('Обязательное поле')
            return errors
        },
        patronymicErrors() {
            const errors = []
            if (!this.$v.patronymic.$dirty) return errors
            !this.$v.patronymic.minLength && errors.push('Минимум 2 символа')
            !this.$v.patronymic.required && errors.push('Обязательное поле')
            return errors
        },
        gradeErrors() {
            const errors = []
            if (!this.$v.grade.$dirty) return errors
            !this.$v.grade.required && errors.push('Обязательное поле')
            return errors
        },

        snilsErrors() {
            const errors = []
            if (!this.$v.snils.$dirty) return errors
            !this.$v.snils.required && errors.push('Обязательное поле')
            !this.$v.snils.snilsVal && errors.push('Введите действущий СНИЛС')
            return errors
        },
        birthErrors() {
            const errors = []
            if (!this.$v.birth.$dirty) return errors
            !this.$v.birth.required && errors.push('Обязательное поле')
            !this.$v.birth.birthVal &&
                errors.push('Введите дату рождения в формате дд.мм.гггг')
            return errors
        },
        phoneErrors() {
            const errors = []
            if (!this.$v.phone.$dirty) return errors
            !this.$v.phone.required && errors.push('Обязательное поле')
            !this.$v.phone.phoneVal &&
                errors.push('Введите номер телефона в формате х-ххх-ххх-хх-хх')
            return errors
        },
        checkboxErrors() {
            const errors = []
            if (!this.$v.terms.$dirty) return errors
            !this.$v.terms.sameAs && errors.push('Обязательное поле')
            return errors
        },
        currentSchoolErrors() {
            const errors = []
            if (!this.$v.currentSchool.$dirty) return errors
            !this.$v.currentSchool.required && errors.push('Обязательное поле')
            return errors
        },
        confirmGradeErrors() {
            const errors = []
            if (!this.$v.confirmGrade.$dirty) return errors
            !this.$v.confirmGrade.required && errors.push('Обязательное поле')
            return errors
        },
        rulesErrors() {
            const errors = []
            if (!this.$v.rules.$dirty) return errors
            !this.$v.rules.required && errors.push('Обязательное поле')
            return errors
        },
    },
    validations: {
        surname: { required, minLength: minLength(2) },
        name: { required, minLength: minLength(2) },
        patronymic: { required, minLength: minLength(2) },
        email: { required, email },
        grade: { required },
        subprofile: { required },
        currentSchool: { required },
        confirmGrade: { required },
        snils: {
            required,
            snilsVal: (val) => /^\d{3}-\d{3}-\d{3} \d{2}$/i.test(val),
        },
        birth: {
            required,
            birthVal: (val) =>
                /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/i.test(
                    val
                ),
        },
        phone: {
            required,
            phoneVal: (val) =>
                /^(8-?|\+?7-?)?(\(?\d{3}\)?)-?(\d-?){6}\d$/i.test(val),
        },
        terms1: {
            sameAs: sameAs(() => true),
        },
        terms2: {
            sameAs: sameAs(() => true),
        },

        terms3: {
            sameAs: sameAs(() => true),
        },
    },
}
</script>

<style>
.mycard {
    background-color: white;
    padding: 15px;
}
.v-btn {
    letter-spacing: 0.02em;
    text-transform: none;
}
.v-label {
    font-size: 12px;
}
.card-text {
    padding-bottom: 0 !important;
}
@media (max-width: 768px) {
    .nopadding {
        padding: 0;
        padding-left: 12px;
        padding-right: 12px;
    }
}
</style>
